.container.contact__container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}


.contact__option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.contact__option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/*  ----     FORM----------------- */
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}

textarea{
    font-family: 'Poppins', sans-serif;
}

/* Button styling */
.btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  /* Overlay for popup */
.overlay {
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup styling */
  .popup {
    background-color: transparent;
    display: none;
    padding: 30px 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Inline text container */
  .popup p {
    font-size: 1.2rem; /* Base size for desktop */
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  }
  
  .popup p span {
    white-space: nowrap;
  }
  
  /* Close button styling */
  .close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }
  
  .close-btn:hover {
    background-color: #c82333;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .popup { /* Overlay for popup */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup styling */
  .popup {
    background-color: transparent;
    display: none;
    padding: 30px 40px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    min-width: 300px;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Inline text container */
  .popup p {
    font-size: 1.2rem; /* Base size for desktop */
  line-height: 1.5;
  font-weight: 500;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  }
  
  .popup p span {
    white-space: nowrap;
  }
  
  /* Close button styling */
  .close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #dc3545;
    color: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }
  
  .close-btn:hover {
    background-color: #c82333;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .popup {
      padding: 20px 25px;
    }
  
    .popup p {
      font-size: 16px;

    }
  
    .close-btn {
      padding: 8px 16px;
      font-size: 14px;
      margin-right: 300px;
    }
  }
  
  @media (max-width: 400px) {
    .popup {
      padding: 15px 20px;
    }
  
    .popup p {
      font-size: 14px;
    }
  
    .close-btn {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
  
      padding: 20px 25px;
    }
  
    .popup p {
      font-size: 16px;

    }
  
    .close-btn {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  
  @media (max-width: 400px) {
    .popup {
      padding: 15px 20px;
    }
  
    .popup p {
      font-size: 14px;
    }
  
    .close-btn {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
  
  


/* --------------------------- for MEDIA QUERIES MEDIUM DEVICES -------------------- */
@media screen and (max-width:1024px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
/* --------------------------- for MEDIA QUERIES SMALL DEVICES -------------------- */
@media screen and (max-width:600px){
    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 1rem;
        width: var(--container-width-ms);
    }
  
}